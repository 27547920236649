.dropdown {
  .input-with-icon {
    margin-bottom: 6px;
  }

  &.dropdown-open {
    .dropdown-choices-wrapper {
      display: block;
    }

    .input-icon svg path {
      stroke: var(--clr-accent-400);

      &.dropdown-arrow {
        transform: rotate(180deg);
        transform-origin: calc(50% + .2px) 50%;
      }
    }
  }

  .dropdown-choices-wrapper {
    width: 100%;
    padding: 12px 1rem;
    border-radius: 10px;
    background-color: var(--clr-neutral-600);
    display: none;

    .dropdown-choices {
      overflow-y: scroll;
      max-height: 4.5rem;

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          & + li {
            margin-top: 2px;
          }

          &.selected {
            color: var(--clr-accent-400);
          }

          &:hover {
            color: var(--clr-accent-400);
            cursor: pointer;
          }
        }
      }
    }

    /* width */
    ::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--clr-neutral-600);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--clr-neutral-200);
      border-radius: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: var(--clr-scrollbar);
      border-radius: 1px;
    }
  }

  &[data-disabled='true'] {
    .input-with-icon {
      pointer-events: none;

      svg path {
        stroke: var(--clr-neutral-200);
      }
    }
  }

  .dropdown-error {
    margin-bottom: 6px;
  }
}
