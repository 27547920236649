.crud-layout {
  display: flex;
  height: 100%;

  .crud-form-wrapper {
    height: 100%;
    flex: 0 0 550px;
    background-color: var(--clr-neutral-500);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .crud-list-wrapper {
    flex-grow: 1;
    padding: .5rem;
    background-color: var(--clr-neutral-500);
    overflow-y: scroll;
  }
}

@media screen and (max-width: 1024px) {
  .home {
    .crud-layout {
      height: fit-content;
      flex-wrap: wrap;

      .crud-form-wrapper {
        height: fit-content;
        flex: 0 0 100%;
      }

      .crud-list-wrapper {
        height: fit-content;

        .list-title {
          display: flex;
        }
      }
    }
  }
}
