//@use "src/stories/icons/Icons";

html {
  font-size: 16px;
  line-height: 18px;

  /*accent*/
  --clr-accent-400: #F5C106;
  --clr-accent-600: #ff7700;

  /*neutral*/
  --clr-neutral-100: #D9D9D9;
  --clr-neutral-200: #8c8c8c;
  --clr-neutral-500: #3D3C40;
  --clr-neutral-600: #313033;
  --clr-neutral-900: #1D1D1F;

  /*specific*/
  --clr-scrollbar: #6c6c6c;
  --clr-table-head: #007564;
  --clr-popup: #2A292C;
  --clr-toast-succeed: #049104;

  background-color: var(--clr-neutral-900);
  color: var(--clr-neutral-100);

  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 400;

  .toaster {
    z-index: 100;
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
