.item-list {
  .list-title {
    display: none;
    justify-content: center;
    align-items: center;
    margin: 1.5rem 0 1rem 0;

    h1 {
      font-weight: normal;
      font-size: 1.5rem;
    }
  }

  .user-roles {
    height: 1.75rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;

    .user-role {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 2px 12px;
      border-radius: 6px;
      outline: 2px solid var(--clr-accent-400);
    }
  }
}
