.app {
  width: 100vw;
  height: 100vh;

  .nav {
    .logo-wrapper {
      overflow: hidden;

      .title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }

  .content {
    height: calc(100vh - 70px);
    overflow-y: scroll;
  }
}

/* width */
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--clr-neutral-600);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--clr-neutral-200);
  border-radius: 1px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--clr-scrollbar);
  border-radius: 1px;
}
