svg {
  &.icon-arrow-up-down {
    .arrow-down {
    }

    .arrow-up {
      transform: rotate(180deg);
      transform-origin: calc(50% + .2px) 50%;
    }

    &.selected {
      path {
        stroke: var(--clr-accent-400);
      }
    }
  }
}
