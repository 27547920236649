.input-radio {
  display: flex;
  gap: .8rem;
  align-items: center;

  & > span {
    display: flex;
    gap: .6rem;
    align-items: center;

    & > span {
      display: flex;
      align-items: center;
      gap: .25rem;
    }
  }

  input[type=radio] {
    outline: none;

    height: 1.2rem;
    width: 1.2rem;

    &:after {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 1.2rem;
      position: relative;
      background-color: var(--clr-neutral-100);
      content: '';
      display: inline-block;
      visibility: visible;
    }

    &:checked:after {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 15px;
      position: relative;
      background-color: var(--clr-accent-400);
      content: '';
      display: inline-block;
      visibility: visible;
    }
  }

  label.input-label {
    color: var(--clr-neutral-200)
  }

  label {
    color: var(--clr-neutral-100)
  }
}
