.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: calc(100vh * .3);
  gap: 1rem;

  .error-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;

    .error-sorry {
      font-size: 20px;
    }

    .error-reason {
      color: var(--clr-accent-400);
    }
  }
}
