nav {
  height: 70px;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--clr-neutral-500);

  border-bottom: 1px solid #1D1D1F;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  a {
    color: var(--clr-neutral-100);
    text-decoration: none;

    &:hover {
      color: var(--clr-accent-400);
    }

    &.active {
      color: var(--clr-accent-400);
    }
  }

  .nav-item {
    height: 100%;
    display: flex;
    gap: 20px;
    align-items: center;

    &.logo-wrapper {

      &:hover {
        cursor: pointer
      }

      .title {
        color: var(--clr-neutral-200);
        font-size: 2rem;
      }
    }
  }
}
