.crud-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  padding: 1.5rem 10px;

  .crud-title {
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-weight: normal;
      font-size: 1.5rem;
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .add-new-button {
    display: flex;
    justify-content: center;
  }
}
