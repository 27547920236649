.input {
  width: 100%;

  label {
    display: block;
    margin-bottom: 2px;
    color: var(--clr-neutral-200);
  }

  input {
    width: 100%;
    padding: 12px 1rem;
    font-size: 1rem;
    border: none;
    color: var(--clr-neutral-100);
    background-color: var(--clr-neutral-600);
    border-radius: 10px;
    font-family: inherit;

    &:focus {
      outline: 2px solid var(--clr-accent-400);
    }
  }

  input:disabled {
    color: var(--clr-neutral-200);
  }

  .input-with-icon {
    background-color: var(--clr-neutral-600);
    display: flex;
    gap: 0;
    border-radius: 10px;

    &:focus-within {
      outline: 2px solid var(--clr-accent-400);
    }

    input {
      border-radius: 10px 0 0 10px;

      &:focus {
        outline: none;
      }
    }

    .input-icon {
      padding: 0 1rem;
      border-radius: 0 10px 10px 0;
      cursor: pointer;

      svg {
        height: 100%;
      }

      &:hover svg path {
        stroke: var(--clr-accent-400);
      }
    }
  }

  .input-error {
    font-size: 14px;
    color: var(--clr-accent-600);
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
