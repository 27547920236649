.input-range {
  .range-slider-wrapper {
    display: flex;
    gap: 0.8rem;
    align-items: center;

    label {
      color: var(--clr-neutral-200);
    }

    .input-range-track {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;


      .input-range-track-lower {
        width: 40%;
        height: 2px;
        border-radius: 1px;
        position: relative;
        background-color: var(--clr-accent-400);
      }

      .input-range-track-upper {
        flex-grow: 1;
        height: 2px;
        border-radius: 1px;
        background-color: var(--clr-neutral-900);
      }

      .input-range-thumb {
        height: 1rem;
        width: 1rem;
        background-color: var(--clr-accent-400);
        border-radius: 50%;

        &:hover {
          cursor: ew-resize;
        }
      }
    }

    .input-range-slider {
      display: flex;
      align-items: center;
      gap: 4px;

      input {
        text-align: right;
        width: 2rem;
        color: var(--clr-neutral-100);
        background: transparent;
        border: none;
        outline: none;
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type=number] {
        -moz-appearance: textfield;
      }
    }
  }

  .input-error {
    font-size: 14px;
    color: var(--clr-accent-600);
  }
}
