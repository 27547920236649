.input-date {

  .input-with-icon {
    margin-bottom: 6px;
  }

  &.calendar-open {
    .calendar-wrapper {
      display: block;
    }

    & > .input-with-icon > .input-icon svg path {
      stroke: var(--clr-accent-400);
    }
  }

  .calendar-wrapper {
    width: 100%;
    display: none;
    padding: 12px 1rem;
    border-radius: 10px;
    background-color: var(--clr-neutral-600);

    .calendar-year-month-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      cursor: pointer;

      .calendar-icon {
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover svg path {
          stroke: var(--clr-accent-400);
        }
      }

      .calendar-year-month {
        display: flex;
        gap: 4px;
        color: var(--clr-accent-400);
      }

      margin-bottom: 1.5rem;
    }

    .calendar-body {
      margin: 0 -1%;
      aspect-ratio: 1.1/1;

      .calendar-years {
        display: grid;
        grid-template-columns: repeat(5, 1fr);

        .calendar-grid-item {
          aspect-ratio: calc(7/5)/1;
          display: flex;
          justify-content: center;
          align-items: center;

          .calendar-year {
            border-radius: 10px;
            width: 80%;
            height: 80%;
            background-color: var(--clr-neutral-500);
            display: flex;
            justify-content: center;
            align-items: center;

            &.year-selected,
            &:hover {
              cursor: pointer;
              color: var(--clr-neutral-900);
              background-color: var(--clr-accent-400);
            }
          }
        }
      }

      .calendar-months {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .calendar-grid-item {
          aspect-ratio: 3.5 /1;
          display: flex;
          justify-content: center;
          align-items: center;

          .calendar-month {
            border-radius: 10px;
            width: 80%;
            height: 80%;
            background-color: var(--clr-neutral-500);
            display: flex;
            justify-content: center;
            align-items: center;

            &.month-selected,
            &:hover {
              cursor: pointer;
              color: var(--clr-neutral-900);
              background-color: var(--clr-accent-400);
            }
          }
        }
      }

      .calendar-day-names {
        display: grid;
        grid-template-columns: repeat(7, 1fr);

        .calendar-grid-item {
          text-align: center;
          aspect-ratio: 1/1;
          display: flex;
          align-items: center;
          justify-content: center;

          &.calendar-day-name {
            aspect-ratio: unset;
            color: var(--clr-neutral-200);
            overflow: hidden;
          }

          .calendar-day {
            background-color: var(--clr-neutral-500);
            width: 80%;
            height: 80%;
            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 10px;

            &.day-selected,
            &.other-month:hover,
            &:hover {
              background-color: var(--clr-accent-400);
              color: var(--clr-neutral-900);
              cursor: pointer;
            }

            &.other-month {
              color: var(--clr-neutral-200);
              opacity: .25;

              &.other-month:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
