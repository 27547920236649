.loader {
  border: 16px solid var(--clr-neutral-600); /* Light grey */
  border-top: 16px solid var(--clr-accent-400); /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
