.multiselect {
  .multiselect-selections-wrapper {
    max-width: 60%;
    padding: 0 2px 0 calc(1rem - 2px);

    &:has(>.multiselect-selections:empty) {
      display: none;
    }

    .multiselect-selections {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 12px;
      overflow-x: scroll;
      height: 100%;
      padding: 2px 2px 0 2px;

      .multiselect-selection {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 2px 6px;
        border-radius: 6px;
        outline: 2px solid var(--clr-accent-400);

        p {
          white-space: nowrap;
        }

        svg {
          height: 100%;
          padding-top: 2px;
          cursor: pointer;

          &:hover path {
            stroke: var(--clr-accent-400);
          }
        }
      }
    }

    /* width */
    ::-webkit-scrollbar {
      position: absolute;
      height: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: var(--clr-neutral-600);
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: var(--clr-neutral-200);
      border-radius: 1px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: var(--clr-scrollbar);
      border-radius: 1px;
    }
  }

  &:has(.multiselect-selections:not(:empty)) .input-with-icon:focus-within {
    outline: none;
  }
}
