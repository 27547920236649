.item-list {
  height: 100%;

  .loader-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5%;
  }

  .table {
    .table-no-data {
      padding-top: 1.5rem;
    }
  }
}
