.home {
  height: 100%;
  width: 100%;

  .loader-wrapper {
    padding-top: 2rem;
    height: 100%;
    display: flex;
    justify-content: center;
  }
}
