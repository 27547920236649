.table {
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    th, td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 1%;
    }

    th:first-of-type,
    td:first-of-type {
      padding-left: 1rem;
    }

    th:last-of-type,
    td:last-of-type {
      padding-right: 1rem;
    }

    th {
      font-weight: unset;
      font-size: 1.25rem;
      color: var(--clr-neutral-200);
      text-align: start;

      .th-content-wrapper {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      &.sortable:hover {
        cursor: pointer;
      }
    }

    tr {
      line-height: 3.5rem;
      border-bottom: 2px solid var(--clr-neutral-600);
      padding-bottom: 1rem;

      &.row-hoverable:hover {
        background-color: var(--clr-neutral-600);
      }

      &.row-clickable {
        cursor: pointer;
      }

      td {
        .table-actions {
          display: flex;
          justify-content: flex-end;
          gap: .3rem;

          .table-action {
            display: flex;
            align-items: center;


            &:hover {
              svg path {
                stroke: var(--clr-accent-400);
              }

              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .table-no-data {
    text-align: center;
    font-size: 1.25rem;
    display: none;
  }

  &:has(tbody:empty) {
    .table-no-data {
      display: block;
    }

    table {
      display: none;
    }
  }
}
