.button {
  button {
    border-radius: 20px;
    padding: 4px 1.5rem;
    outline: none;
    border: none;
    background-color: var(--clr-accent-400);
    color: var(--clr-neutral-900);
  }

  button:hover {
    cursor: pointer;
  }

  button:active {
    background-color: var(--clr-neutral-600);
    color: var(--clr-accent-400);
  }

  button:disabled {
    cursor: unset;
    background-color: var(--clr-neutral-600);
    color: var(--clr-neutral-200);
  }
}
