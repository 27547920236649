.toaster {
  position: fixed;

  bottom: 12px;
  right: 12px;
  display: flex;
  flex-direction: column;
  gap: .5rem;

  .toast {
    width: 400px;
    display: flex;
    gap: 1rem;
    padding: 10px 1rem;
    background-color: var(--clr-popup);
    border-radius: 10px;
    transition: transform .6s ease-in-out;
    animation: toast-in .7s, toast-out .7s 5s;
    animation-fill-mode: forwards;

    .toast-icon {
      display: flex;
      align-self: center;
      width: 35px;

      svg.succeed path {
        stroke: var(--clr-toast-succeed);
      }

      svg.failed path {
        stroke: var(--clr-accent-600);
      }
    }

    .toast-body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      //gap: .2rem;

      .toast-title {
        font-size: 20px;
      }
    }

    .toast-close {
      height: 100%;
      display: flex;
      flex-direction: column;

      &:hover {
        cursor: pointer;

        svg path {
          stroke: var(--clr-accent-400);
        }
      }
    }
  }
}

@keyframes toast-in {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes toast-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(110%);
    visibility: hidden;
  }
}
